<template>
    <b-overlay :show="isLoading">
        <b-card class="white-bg">
            <b-row>
                <b-col>
                    <b-table-simple responsive bordered striped>
                        <b-thead>
                            <b-tr>
                                <b-th colspan="4">{{ $route.meta.module === 'customer' ? 'Cliente' : 'Proveedor' }}</b-th>
                            </b-tr>
                            <b-tr class="table-header-color">
                                <b-th style="width: 25%;">Tipo</b-th>
                                <b-th style="width: 25%;">Es cliente</b-th>
                                <b-th style="width: 25%;">Es proveedor</b-th>
                                <b-th style="width: 25%;"></b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-td>Persona {{ businessEntity.type === 'FISICA' ? 'Física' : 'Moral' }}</b-td>
                                <b-td>{{ businessEntity.isCustomer ? "Sí" : "No" }}</b-td>
                                <b-td>{{ businessEntity.isSupplier ? "Sí" : "No" }}</b-td>
                                <b-td></b-td>
                            </b-tr>
                            <template v-if="businessEntity.type === 'FISICA'">
                                <b-tr class="table-header-color">
                                    <b-th>Nombre</b-th>
                                    <b-th>Apellido paterno</b-th>
                                    <b-th>Apellido materno</b-th>
                                    <b-th>RFC</b-th>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ businessEntity.name }}</b-td>
                                    <b-td>{{ businessEntity.firstLastName }}</b-td>
                                    <b-td>{{ businessEntity.secondLastName }}</b-td>
                                    <b-td>{{ businessEntity.rfc }}</b-td>
                                </b-tr>
                            </template>
                            <template v-else>
                                <b-tr class="table-header-color">
                                    <b-th colspan="2">Razón social</b-th>
                                    <b-th>Régimen capital</b-th>
                                    <b-th>RFC</b-th>
                                </b-tr>
                                <b-tr>
                                    <b-td colspan="2">{{ businessEntity.legalName }}</b-td>
                                    <b-td>{{ businessEntity.legalEntityType }}</b-td>
                                    <b-td>{{ businessEntity.rfc }}</b-td>
                                </b-tr>
                            </template>
                            <b-tr class="table-header-color">
                                <b-th colspan="4">Nombre comercial</b-th>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="4">{{ businessEntity.commercialName ? businessEntity.commercialName : 'Sin registro' }}</b-td>
                            </b-tr>
                            <template v-if="businessEntity.type === 'MORAL'">
                                <b-tr class="table-header-color">
                                    <b-th>Nombre(s) del contacto</b-th>
                                    <b-th>Apellido paterno del contacto</b-th>
                                    <b-th>Apellido materno del contacto</b-th>
                                    <b-th></b-th>
                                </b-tr>
                                <b-tr>
                                    <b-td>{{ businessEntity.name }}</b-td>
                                    <b-td>{{ businessEntity.firstLastName }}</b-td>
                                    <b-td>{{ businessEntity.secondLastName }}</b-td>
                                    <b-td></b-td>
                                </b-tr>
                            </template>
                            <b-tr class="table-header-color">
                                <b-th>Teléfono 1</b-th>
                                <b-th>Teléfono 2</b-th>
                                <b-th>Email 1</b-th>
                                <b-th>Email 2</b-th>
                            </b-tr>
                            <b-tr>
                                <b-td>{{ businessEntity.phone1 ? businessEntity.phone1 : 'Sin registro' }}</b-td>
                                <b-td>{{ businessEntity.phone2 ? businessEntity.phone2 : 'Sin registro' }}</b-td>
                                <b-td>{{ businessEntity.email1 ? businessEntity.email1 : 'Sin registro'}}</b-td>
                                <b-td>{{ businessEntity.email2 ? businessEntity.email2 : 'Sin registro' }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <template #footer>
                <div class="text-right">
                    <b-button variant="outline-secondary" :to="{ name: ($route.meta.module === 'customer' ? 'CustomerIndex' : 'SupplierIndex') }" class="mr-2"><font-awesome-icon icon="angle-left"></font-awesome-icon> Regresar</b-button>
                    <b-button variant="secondary" :to="{ name: ($route.meta.module === 'customer' ? 'CustomerEdit' : 'SupplierEdit'), params: { id: businessEntity.id }}" v-if="businessEntity.id">
                        <font-awesome-icon icon="edit"></font-awesome-icon> Modificar
                    </b-button>
                </div>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>
import { businessEntityUrl } from '@routes';
import * as constants from '@constants';

export default {
    data() {
        return {
            businessEntity: {
                id: 0,
                name: '',
                firstLastName: '',
                secondLastName: '',
                legalName: '',
                commercialName: '',
                legalEntityType: '',
                rfc: '',
                phone1: '',
                phone2: '',
                email1: '',
                email2: '',
                type: '',
                isCustomer: true,
                isSupplier: false
            },
            breadcrumb: {},
            isLoading: false
        };
    },
    created() {
        this.businessEntity.id = this.$route.params.id ?? 0;

        this.loadBreadcrumb(this.$route.meta.module);

        this.loadData();
    },
    methods: {
        loadData() {
            this.isLoading = true;

            this.axios.get(businessEntityUrl(this.$route.params.id)).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.businessEntity = JSON.parse(JSON.stringify(response.data.businessEntity));
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        loadBreadcrumb(module) {
            if (module === 'supplier') {
                this.breadcrumb = {
                    title: 'Proveedores',
                    path: [
                        {
                            name: 'SupplierIndex',
                            text: 'Proveedores'
                        }
                    ]
                };
            } else if (module === 'customer') {
                this.breadcrumb = {
                    title: 'Clientes',
                    path: [
                        {
                            name: 'CustomerIndex',
                            text: 'Clientes'
                        }
                    ]
                };
            }

            this.breadcrumb.path.push({
                name: this.$route.name,
                text: 'Detalle'
            });

            this.$emit('update-breadcrumb', this.breadcrumb);
        }
    },
    computed: {
    }
};
</script>

<style scoped>

</style>